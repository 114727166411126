import React, { useEffect, useRef, useState } from 'react'
import './ContentFour.css'
import {motion } from 'framer-motion';
import { toast } from 'react-toastify';

const ContentFour = ({data}) => {

    // console.log(data?.coupons?.timer);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    useEffect(() => {
      const savedTimestamp = localStorage.getItem('timestamp');
      const currentTimestamp = new Date().getTime();
  
      if (!savedTimestamp) {
        // Save the current timestamp if none exists
        localStorage.setItem('timestamp', currentTimestamp.toString());
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      } else {
        // Load the saved timestamp and calculate initial counter values
        const storedTime = parseInt(savedTimestamp);
        const now = new Date().getTime();
        const elapsedTime = now - storedTime;
        const destination = storedTime + (data?.coupons?.timer * 60 * 60 * 1000); // 6 hours
  
        if (elapsedTime < 0 || destination < now) {
          // Reset if expired or timestamp is invalid
          localStorage.removeItem('timestamp');
          setDays(0);
          setHours(0);
          setMinutes(0);
          setSeconds(0);
          return;
        }
  
        const timeLeft = destination - now;
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
  
        // Start interval immediately to ensure continuity
        const intervalId = setInterval(() => {
          const now = new Date().getTime();
          const remainingTime = destination - now;
  
          if (remainingTime <= 0) {
            clearInterval(intervalId);
            // Handle the end state appropriately
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
          } else {
            const updatedDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
            const updatedHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const updatedMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
            const updatedSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  
            setDays(updatedDays);
            setHours(updatedHours);
            setMinutes(updatedMinutes);
            setSeconds(updatedSeconds);
          }
        }, 1000);
  
        // Clean up the interval on unmount
        return () => clearInterval(intervalId);
      }
    }, [data]);
    // useEffect(()=>{
    //     countDown()
    // },[]) 

    const inputRef = useRef(null);
    const copyToClipboard = () => {
      inputRef.current.select();
      document.execCommand("copy");
      toast.success("code copied successfully");
    };

    // console.log(seconds.toString().length);
  return (
    <div className="ContentFour container">
      <span className="offer">خصم {data?.coupons?.discount_percentage}%</span>
      <div className="ContentFour_content">
        <div className="ContentFour_content_header">
          <h1>خصم {data?.coupons?.discount_percentage}% من {data?.name_ar}</h1>
          <p>
          {data?.coupons?.description_ar}
          </p>
        </div>
        <div className="ContentFour_content_countDown">
          <span>الخصم ينتهي خلال</span>
            {/* <AnimatePresence> */}
          <h2>
            <span>
                {/* {days > 0 ? days : "00"} */}
                {days.toString().length === 2 ? days : days.toString().length === 1 ? '0' + days : '00'} 
            </span>
            :
            <span>
                {/* {hours > 0 ? hours : "00"} */}
                {hours.toString().length === 2 ? hours : hours.toString().length === 1 ? '0' + hours : '00'} 
            </span>
            :
            <span>
                {/* {minutes > 0 ? minutes : "00"} */}
                {minutes.toString().length === 2 ? minutes : minutes.toString().length === 1 ? '0' + minutes : '00'} 

            </span>
            :
            {/* <span className='secDiv'> */}
            {/* </span> */}
            {/* {seconds > 0 ? seconds : "00"} */}
                    <motion.span
                        key={seconds}
                        className='sec'
                        exit={{ y: 10, opacity: 1, position: "absolute" }}
                        initial={{ y: 10, opacity: 1 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            ease: "easeInOut", 
                            duration: 0.7,
                        }}
                    >
                        {seconds.toString().length === 2 ? seconds : seconds.toString().length === 1 ? '0' + seconds : '00'} 
                    </motion.span>
          </h2>
            {/* </AnimatePresence> */}
          
        </div>



        {
            data?.coupons &&
        data?.coupons?.type === "link-code" ? (
        
        <div className="ContentFour_content_copyBtn">
        <button>
             <a href={data?.coupons?.link} target='blank'>
                احصل على الخصم
            </a>
            </button>
      </div>
      ) : (
        <div className="ContentFour_content_copyBtn">
        <input type="text" defaultValue={data?.coupons?.code} readOnly ref={inputRef} />
        <button onClick={copyToClipboard}>احصل على الكود</button>
      </div>
      )}


        
        <a href={data?.link_en} className="visitFour">
          اذهب الي الموقع
        </a>
      </div>
    </div>
  );
}

export default ContentFour