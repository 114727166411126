import React, { useRef } from 'react'
import './ThreeCopy.css'
import { toast } from 'react-toastify';
const ThreeCopy = ({data}) => {
  const inputRef = useRef(null);
  const copyToClipboard = () => {
  inputRef.current.select();
  document.execCommand('copy');
      toast.success("code copied successfully")
    };
  return (
    <div className='ThreeCopy'>

{data?.coupons?.type === "link-code" ? (
        <div className='ThreeCopy_btn'>
        <button className='ThreeCopy_GETBTN getOffer'>
          <a href={data?.coupons?.link}>
            GET OFFER
          </a>
        </button>
    </div>
      ) : (
        <div className='ThreeCopy_btn'>
        <input type="text" defaultValue={data?.coupons?.code} readOnly ref={inputRef} />
        <button className='ThreeCopy_GETBTN' onClick={copyToClipboard}>
        GET CODE
        </button>
    </div>
      )}
       
          <a href={data?.link_en} className='visit'>
            VISIT WEBSITE
          </a>
    </div>
  )
}

export default ThreeCopy