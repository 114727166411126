import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home/Home";
import Coupon from "./pages/CouponPage/Coupon";
import Coupont from './pages/CouponTwo/Coupont';
import CouponThree from './pages/CouponThree/CouponThree';
import CouponFour from './pages/CouponFour/CouponFour';
import { useContext } from 'react';
import { DataContext } from './contextApi/DataContext';
import RedirectOne from './pages/redirectone/RedirectOne';
import RedirectTwoPage from './pages/redirectTow/RedirectTwoPage';
import RedirectThreePage from './pages/redirectThree/RedirectThreePage';
import RedirectFourPage from './pages/redirectFour/RedirectFourPage';
import NotFound from './pages/not-found/NotFound';


function App() {
  const {data} = useContext(DataContext);
  return (
    <Router>
      <Routes>
        <Route exact  path="/" element={<Home />}/>

        <Route exact  path="/coupon/:id" element={
    // loading ? (
      <>
        {data?.domain?.theme.name === "default_1" && <Coupon />}
        {data?.domain?.theme.name === "default_2" && <Coupont />}
        {data?.domain?.theme.name === "default_3" && <CouponThree />}
        {data?.domain?.theme.name === "default_4" && <CouponFour />}
        {!data?.domain?.theme.name && <CouponFour />}
      </>
    // ) : (
    //   <Navigate to={`/${id}`} />
    // )
  } />
        <Route exact  path='/:id' element={
          data?.domain?.theme.name === "default_1" ? <RedirectOne/>
          : data?.domain?.theme.name === "default_2" ? <RedirectTwoPage/> 
          :data?.domain?.theme.name === "default_3" ? <RedirectThreePage />
          :data?.domain?.theme.name === "default_4" ?<RedirectFourPage/>
          :<RedirectFourPage/>
        }/>
          <Route path={`*`} element={<NotFound />} />

      </Routes>
    </Router>
  );
}

export default App;
